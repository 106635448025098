import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"

import cobertura_analisis from '../../../static/img/irstrat/cobertura_analisis-en.jpg'
import free_float from '../../../static/img/irstrat/free_float-en.jpg'
import planta_plata from '../../../static/img/irstrat/estrategia11.jpg'

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="IR Fundamentals"/>
            <Banner title="IR Fundamentals"/>

            <section className="offer_area_two propuesta">
                <div className="container">
                    <div className="row strategy_info align-items-center">
                        <div className="col-lg-12">
                            <div className="ex_content">
                                <h2>Tangible benefits</h2>
                                <span className="sec_border"></span>
                                <p>It is a fact that an efficient Investor Relations (IR) function generates a higher
                                    valuation in a company’s securities; at least, between 10-15% (according to NIRI,
                                    “National Investor Relations Institute”).</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="offer_area_two propuesta bg-white">
                <div className="container">
                    <div className="row strategy_info align-items-center">
                        <div className="col-lg-12">
                            <div className="ex_content">
                                <p>Suppose this benefit was generated for a specific issuer (obtaining 15% of
                                    incremental valuation), thanks to a transition from an intermediate IR program (only
                                    focused on financial reporting and regulatory compliance, without proper
                                    communication of its investment thesis), to a premium IR program (focused on
                                    identity and image, strategic message and intelligence, and lobbying). Now, assume
                                    that we are talking about a small cap issuer (market cap of US$1 billion) and that,
                                    capitalizing on the incremental valuation, it will execute a successful
                                    go-to-market, i.e., a secondary public offer of 20% of its equity, thus, derived
                                    from the positive momentum, this company would receive the following benefits:</p>

                                <p><strong>US$30 million liquid resources from the offering; and US$120 million from the
                                    “mark-to-market” effect generated by the higher valuation of the remaining 80%
                                    equity.</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="propuesta">
                <div className="container">
                    <h3 className="text-center">Effective solution</h3>
                    <div className="row strategy_info align-items-center mb-5">
                        <div className="col-lg-5">
                            <div className="ex_content">
                                <img className="wow rotateInDownLeft" data-wow-delay="0.2s" data-wow-duration="2.0s"
                                     src={planta_plata} alt={" "}/>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <p>This is a clear example that there are few investments as profitable and tangible as the
                                execution of a <strong><i>premium</i></strong> IR program, without internalizing the benefits associated to the
                                cost of debt.</p>
                            <p>Continuing with the exercise, suppose that the successful transition of the issuer’s IR
                                program is 50% due to the effective solutions provided by an expert consulting firm,
                                such as IRStrat, during two years of service, with a cost of US$250,000. Therefore, the
                                return on investment (ROI) would be 59.50 times, only from the liquid component of the
                                benefits (US$30 million). This example clearly highlights the advantages of a premium IR
                                program, constituting a competitive advantage.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="propuesta">
                <div className="container">
                    <h3 className="text-center">Strong evidences</h3>
                    <div className="row strategy_info align-items-center mb-5">
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <p>The efficient IR management leads to an appropriate visibility, and an appropriate
                                    visibility to a wider analyst coverage, and in turn to an improved trading (measured
                                    in terms of liquidity):</p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <img className="w-100  wow zoomIn" data-wow-delay="0.2s" data-wow-duration="2.0s"
                                 src={cobertura_analisis} alt={" "}/>
                        </div>
                    </div>
                    <div className="row strategy_info align-items-center mb-5">
                        <div className="col-lg-5">
                            <img className="w-100  wow fadeIn" data-wow-delay="0.2s" data-wow-duration="2.0s"
                                 src={free_float} alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <p>The improved trading and liquidity lead to the market opening, i.e. the existence of
                                    competitive prices and volume depth for robust placements that results in a high
                                    level of trading volume; thanks to a greater floating in the market, derived from
                                    the virtuous circle generated by the implementation of premium IR practices.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row strategy_info align-items-center mb-5">
                        <div className="col-lg-12">
                            <p>Executing solid IR practices is certainly an effective method for gaining a solid market
                                positioning and generate tangible effects in valuations and funding sources.</p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
